import './YandexMap.scss';
import { LitElement, html } from 'lit';
import loadScript from '../../../modules/load-script';

export class YandexMap extends LitElement {
    constructor() {
        super();

        this._closeCard = this._closeCard.bind(this);

        this.instance = null;
        this.zoom = 11;
        this._isFetching = false;
        this.currentCoords = [0, 0];
        this.data = this.getAttribute('map-data') ? JSON.parse(this.getAttribute('map-data')) : null;
        this.marks = [];
        this.activeTabs = [];
        this.clusterer;
        this.isCardVisible = false;

        this._observer = new IntersectionObserver((entries, obs) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    obs.unobserve(entry.target);
                    this._init();
                }
            });
        });
    }

    static get properties() {
        return {
            centerLat: {
                type: Number,
                attribute: 'center-lat',
                reflect: true,
            },
            centerLng: {
                type: Number,
                attribute: 'center-lng',
                reflect: true,
            },
            apiKey: {
                type: String,
                attribute: 'api-key',
            },
            zoom: {
                type: Number,
                reflect: true,
            },
            marker: {
                type: String,
                reflect: true,
            },
            data: {
                type: Object,
            },
            _isFetching: {
                type: Boolean,
                attribute: false,
            },
            _error: {
                attribute: false,
            },
            isCardVisible: {
                type: Boolean,
            },
        };
    }

    createRenderRoot() {
        return this;
    }

    _init() {
        this._error = null;
        this._isFetching = true;
        loadScript(`https://api-maps.yandex.ru/2.1/?apikey=${this.apiKey}&lang=ru_RU`)
            .then(() => {
                const { ymaps } = window;
                ymaps.ready(() => {
                    const _this = this;
                    const margin = window.matchMedia('(max-width: 1024px)').matches ? 50 : [50, 50, 50, 400];
                    this.instance = new ymaps.Map(
                        this.renderRoot.querySelector('.map'),
                        {
                            center: [this.centerLat, this.centerLng],
                            zoom: this.zoom,
                            margin: margin,
                        },
                        { smoothZooming: true, smoothScroll: true, suppressMapOpenBlock: true },
                    );
                    this.instance.container.fitToViewport();

                    const zoomControl = new ymaps.control.ZoomControl({
                        options: {
                            position: {
                                left: 'auto',
                                right: 10,
                                top: 60,
                            },
                        },
                    });
                    this.instance.controls.add(zoomControl);

                    const elements = [];

                    if (this.data) {
                        if (this.data.placemarks?.[0]?.coords) {
                            this.currentCoords = this.data.placemarks[0].coords;
                        }

                        this.data.placemarks.forEach((placemark) => {
                            const animatedLayout = ymaps.templateLayoutFactory.createClass(
                                `<div class="map-placemark js-map-placemark" data-coords="${placemark.coords}">
                                    <div class="map-placemark__button js-map-marker">
                                        <svg width="12" height="23" viewBox="0 0 12 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.8604 0.204345C7.14737 0.79388 3.41365 2.10741 1.14859 3.63813C0.217749 4.26904 0.0522655 4.97234 0.693514 5.60325L1.08654 5.97559L1.13825 5.40674L1.18997 4.83789L2.27595 4.36212C4.48929 3.37956 7.70588 2.43837 10.2192 2.04535L11.3775 1.85918V0.990392C11.3775 0.369829 11.3465 0.131946 11.2534 0.142289C11.181 0.152631 11.0052 0.18366 10.8604 0.204345Z" fill="#EDEDED"/>
                                            <path d="M10.6004 2.27329C10.4349 2.29397 9.59716 2.45946 8.73871 2.62494C6.29783 3.11105 3.75352 3.8971 1.84011 4.75554L1.24023 5.02445V5.72776C1.24023 6.15181 1.28161 6.44141 1.34366 6.44141C1.40572 6.44141 1.69531 6.31729 1.98491 6.1725C2.7813 5.76913 4.77745 5.13822 6.05994 4.89C7.39416 4.63143 9.69024 4.37286 10.6831 4.37286H11.3761V3.28688C11.3761 2.07678 11.4382 2.15952 10.6004 2.27329Z" fill="#EDEDED"/>
                                            <path d="M9.47394 4.7968C5.92638 5.03469 2.67877 5.85176 1.08599 6.91706C0.34131 7.40317 0 7.89962 0 8.48916C0 8.73738 0.0723991 9.06835 0.144798 9.22349C0.330967 9.57514 0.889474 10.0716 1.44798 10.3922L1.86169 10.6301V14.3225V18.0148L2.35814 18.0769C2.62705 18.1079 3.20624 18.2217 3.65098 18.3147L4.44737 18.5009V12.9469C4.44737 7.98236 4.46806 7.39283 4.61285 7.33077C4.70594 7.29974 4.79902 7.26871 4.81971 7.26871C4.84039 7.26871 4.86108 9.82337 4.86108 12.9365V18.6043L6.07118 19.2146C6.73311 19.5559 7.32265 19.8558 7.36402 19.8662C7.40539 19.8868 7.44676 16.9495 7.44676 13.3296C7.44676 7.54797 7.46745 6.75158 7.6019 6.75158C7.73636 6.75158 7.75704 7.56865 7.75704 13.4743V20.1971L8.56378 20.9728C9.01886 21.3969 9.58771 22.0278 9.83593 22.3691L10.291 23L10.322 14.7672L10.3427 6.54472H10.8599H11.377V5.61388V4.68303L11.046 4.69338C10.8599 4.70372 10.1566 4.75543 9.47394 4.7968Z" fill="#EDEDED"/>
                                        </svg>
                                    </div>
                                    <div class="map-card">
                                        <button
                                            class="card-close-btn"
                                            aria-label="Закрыть"
                                            @click="${this._closeCard}"
                                        >
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 1L11 11M1 11L11 1" stroke="#3B3B3B"/>
                                            </svg>
                                        </button>
                                        <div class="map-card__inner">
                                            <div class="map-card__title">
                                                ${placemark.address ? placemark.address : ''}
                                            </div>
                                            <div class="map-card__time">
                                                ${placemark.working_mode ? placemark.working_mode : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>`,
                                {
                                    build() {
                                        animatedLayout.superclass.build.call(this);
                                        const element =
                                            this.getParentElement().getElementsByClassName('map-placemark')[0];
                                        elements.push(element);
                                        // Если метка выбрана, то увеличим её размер.
                                        const size = 40;
                                        // Зададим фигуру активной области.
                                        this.getData().options.set('shape', {
                                            type: 'Circle',
                                            coordinates: [size / 2, size / 2],
                                            radius: size / 2,
                                        });

                                        // Если метка выбрана, то зададим класс и запустим анимацию.
                                        if (this.isActive) {
                                            element.classList.add('is-active');
                                        } else if (this.inited) {
                                            element.classList.remove('is-active');
                                        }

                                        const closeAll = () => {
                                            _this.isCardVisible = false;
                                            if (_this.activeMark && _this.activeMark !== this) {
                                                _this.activeMark.isActive = false;
                                            }
                                            _this.renderRoot
                                                .querySelector('.map-card.is-active')
                                                ?.classList.remove('is-active');
                                            elements.forEach((el) => {
                                                el.classList.remove('is-active');
                                            });
                                        };

                                        _this.addEventListener('overlay-click', closeAll);

                                        if (!this.inited) {
                                            this.inited = true;
                                            this.isActive = false;

                                            let offsetY = 0.05;
                                            let offsetX = 0.08;

                                            if (window.matchMedia('(max-width: 767px)').matches) {
                                                offsetY = 0.01;
                                                offsetX = 0.07;
                                            }

                                            // При клике по метке будем перестраивать макет.
                                            this.getData().geoObject.events.add(
                                                'click',
                                                function (event) {
                                                    closeAll();

                                                    const mark = event.get('target');
                                                    const index = _this.marks.indexOf(mark);
                                                    const coords = mark.geometry?._coordinates;

                                                    if (coords && _this.instance) {
                                                        _this.instance
                                                            .setCenter(
                                                                [
                                                                    Number(coords[0]) + offsetY,
                                                                    Number(coords[1]) + offsetX,
                                                                ],
                                                                11,
                                                                {
                                                                    checkZoomRange: true,
                                                                    duration: 3,
                                                                },
                                                            )
                                                            .then(() => {
                                                                this.isActive = !this.isActive;
                                                                _this.isCardVisible = this.isActive;
                                                                if (this.isActive) {
                                                                    element.classList.add('is-active');
                                                                    if (index > -1) {
                                                                        element
                                                                            .querySelector('.map-card')
                                                                            .classList.add('is-active');
                                                                    }
                                                                }
                                                                _this.activeMark = this;
                                                            });
                                                    }
                                                },
                                                this,
                                            );
                                            this.getData().geoObject.events.add(
                                                'mouseenter',
                                                function () {
                                                    element.classList.add('is-hovered');
                                                },
                                                this,
                                            );
                                            this.getData().geoObject.events.add(
                                                'mouseleave',
                                                function () {
                                                    element.classList.remove('is-hovered');
                                                },
                                                this,
                                            );
                                        }
                                    },
                                },
                            );

                            const markOptions = {
                                iconLayout: animatedLayout,
                                hasHint: false,
                                hasBalloon: false,
                            };
                            const mark = new ymaps.Placemark(placemark.coords, {}, markOptions);
                            this.marks.push(mark);
                            this.instance.geoObjects.add(mark);
                        });

                        this.dispatchEvent(new Event('init'));

                        this.clusterer = new ymaps.Clusterer({
                            clusterIconLayout: ymaps.templateLayoutFactory.createClass(`
                                <div class="cluster-icon">
                                    <span class="cluster-icon__count">{{ properties.geoObjects.length }}</span>
                                    <svg width="12" height="23" viewBox="0 0 12 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8604 0.204345C7.14737 0.79388 3.41365 2.10741 1.14859 3.63813C0.217749 4.26904 0.0522655 4.97234 0.693514 5.60325L1.08654 5.97559L1.13825 5.40674L1.18997 4.83789L2.27595 4.36212C4.48929 3.37956 7.70588 2.43837 10.2192 2.04535L11.3775 1.85918V0.990392C11.3775 0.369829 11.3465 0.131946 11.2534 0.142289C11.181 0.152631 11.0052 0.18366 10.8604 0.204345Z" fill="#EDEDED"/>
                                        <path d="M10.6004 2.27329C10.4349 2.29397 9.59716 2.45946 8.73871 2.62494C6.29783 3.11105 3.75352 3.8971 1.84011 4.75554L1.24023 5.02445V5.72776C1.24023 6.15181 1.28161 6.44141 1.34366 6.44141C1.40572 6.44141 1.69531 6.31729 1.98491 6.1725C2.7813 5.76913 4.77745 5.13822 6.05994 4.89C7.39416 4.63143 9.69024 4.37286 10.6831 4.37286H11.3761V3.28688C11.3761 2.07678 11.4382 2.15952 10.6004 2.27329Z" fill="#EDEDED"/>
                                        <path d="M9.47394 4.7968C5.92638 5.03469 2.67877 5.85176 1.08599 6.91706C0.34131 7.40317 0 7.89962 0 8.48916C0 8.73738 0.0723991 9.06835 0.144798 9.22349C0.330967 9.57514 0.889474 10.0716 1.44798 10.3922L1.86169 10.6301V14.3225V18.0148L2.35814 18.0769C2.62705 18.1079 3.20624 18.2217 3.65098 18.3147L4.44737 18.5009V12.9469C4.44737 7.98236 4.46806 7.39283 4.61285 7.33077C4.70594 7.29974 4.79902 7.26871 4.81971 7.26871C4.84039 7.26871 4.86108 9.82337 4.86108 12.9365V18.6043L6.07118 19.2146C6.73311 19.5559 7.32265 19.8558 7.36402 19.8662C7.40539 19.8868 7.44676 16.9495 7.44676 13.3296C7.44676 7.54797 7.46745 6.75158 7.6019 6.75158C7.73636 6.75158 7.75704 7.56865 7.75704 13.4743V20.1971L8.56378 20.9728C9.01886 21.3969 9.58771 22.0278 9.83593 22.3691L10.291 23L10.322 14.7672L10.3427 6.54472H10.8599H11.377V5.61388V4.68303L11.046 4.69338C10.8599 4.70372 10.1566 4.75543 9.47394 4.7968Z" fill="#EDEDED"/>
                                    </svg>
                                </div>`),

                            // Чтобы метка была кликабельной, переопределим ее активную область.
                            clusterIconShape: {
                                type: 'Rectangle',
                                coordinates: [
                                    [0, 0],
                                    [60, 60],
                                ],
                            },
                            hasBalloon: false,
                            hasHint: false,
                            gridSize: 128,
                        });

                        this.clusterer.add(this.marks);
                        this.instance.geoObjects.add(this.clusterer);
                    }

                    this.instance.behaviors.disable('scrollZoom');

                    this.instance.events.add('click', this._closeCard);

                    window.addEventListener('resize', this._onResize);

                    const mapLinks = document.querySelectorAll('.js-map-link');
                    mapLinks.forEach((link) => link.addEventListener('click', () => this._setCoords(link)));

                    // смотрим, еслть ли офис в городе и открываем попап этого офиса
                    this._findOffice();
                });
            })
            .catch((err) => {
                this._error = err;
                throw err;
            })
            .finally(() => {
                this._isFetching = false;
            });
    }

    connectedCallback() {
        super.connectedCallback();

        if (!this.apiKey) {
            throw new Error('API key not provided.');
        }

        this._observer.observe(this);
    }

    attributeChangedCallback(name, oldVal, newVal) {
        super.attributeChangedCallback(name, oldVal, newVal);
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        this._observer.disconnect();
        window.removeEventListener('resize', this._onResize);
        this._error = null;

        if (this.instance) {
            this.instance.destroy();
            this.instance = null;
        }
    }

    _onResize() {
        this.instance?.container.fitToViewport();
    }

    _renderMap() {
        if (this._isFetching) {
            return html`<div class="map-loader">Загружаем карту...</div>`;
        }

        if (this._error) {
            return html`<div>${this._error.message}</div>`;
        }

        return '';
    }

    _closeCard() {
        this.dispatchEvent(new Event('overlay-click', { composed: true }));
    }

    _hoverPoint(coords) {
        let offsetY = 0.05;
        let offsetX = 0.08;

        if (window.matchMedia('(max-width: 767px)').matches) {
            offsetY = 0.01;
            offsetX = 0.07;
        }
        setTimeout(() => {
            const placemarksOnMap = document.querySelectorAll('.js-map-placemark');
            placemarksOnMap.forEach((mark) => {
                const markCoords = mark.dataset.coords ? mark.dataset.coords.split(',') : [];
                if (markCoords.length && markCoords[0] == coords[0] && markCoords[1] == coords[1]) {
                    mark.classList.add('is-hovered', 'is-active');

                    // показываем попап
                    const pointPopup = mark.querySelector('.map-card');
                    if (pointPopup) pointPopup.classList.add('is-active');

                    // сдвигаем карту чуть ниже и левее, чтобы было видно поп ап
                    if (this.instance) {
                        this.instance.setCenter([Number(coords[0]) + offsetY, Number(coords[1]) + offsetX], 11);
                    }
                } else {
                    mark.classList.remove('is-hovered');
                }
            });
        }, 200);
    }

    _findOffice() {
        // Если у офиса есть адрес (режим работы), то открываем попап офиса
        const offices = document.querySelectorAll('.js-map-search-office:not(.is-hide)');
        if (offices.length == 1) {
            const address = offices[0].querySelector('.js-work-time');

            if (address) {
                const coordsContainer = offices[0].querySelector('.js-map-link');
                const officeCoords = coordsContainer.dataset.coords ? JSON.parse(coordsContainer.dataset.coords) : null;

                if (officeCoords) {
                    this._hoverPoint(officeCoords);
                }
            }
        }
    }

    _setCoords(link) {
        const coords = link.dataset.coords ? JSON.parse(link.dataset.coords) : null;
        if (this.instance && coords) {
            link.classList.contains('js-map-link-city')
                ? this.instance.setCenter(coords, 11)
                : this.instance.setCenter(coords, 15);
            // если это кнопка "Показать на карте"
            if (!link.classList.contains('js-map-link-city')) {
                this._hoverPoint(coords);
            } else {
                // если это выбор города
                this._findOffice();
            }
        }
    }

    render() {
        return html` <div class="map-container">
            <div class="map">${this._renderMap()}</div>
        </div>`;
    }
}
